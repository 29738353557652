import React from 'react'
import styled, { keyframes } from 'styled-components'
import { below, breakpointIntegers } from '../styles/abstracts/breakpoints.js'
import { FaTimes, FaStar, FaRegFilePdf, FaCalendarAlt } from 'react-icons/fa'
import schedulePdf from '../images/AllianceScheduleAugust2024.pdf'

const Root = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  width: 93%;
  margin: auto;
`
//testing Bitbucket CI

const Button = styled.button`
  background-color: #ffbc00;
  width: 160px;
  padding: 10px;
  height: auto;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  text-decoration: none;
  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    outline: 0;
  }
  cursor: pointer;
`
const Container = styled.div`
  height: auto;
  width: 93%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: ${props => props.theme.color.white};
  justify-content: space-evenly;
  display: flex;
  font-family: Source Sans Pro;
  flex-direction: row;
  ${below.md} {
    height: auto;
    overflow: none;
  }
`
const DaysContainer = styled.div`
  flex: 16;
  display: flex;
  margin: 1px;
  ${below.md} {
    flex-direction: column;
    flex: 6;
  }
`
const TimeColumn = styled.div`
  flex: 1;
  margin-top: 12px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  ${below.md} {
    margin-right: 1px;
  }
`
const TimeContainer = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  @media (max-width: 1700px) {
    flex: 1.5;
  }
  ${below.lg} {
    flex: 2;
  }
  ${below.md} {
    flex: 1;
  }
  @media (max-width: 505px) {
    flex: 1.5;
  }
  @media (max-width: 375px) {
    flex: 2;
  }
`
const DayColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;

  ${below.md} {
    margin-bottom: 50px;
  }
`
const WeekdayText = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #222222;
  margin-bottom: 20px;
`
const GreyText = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #bbbbbb;
  float: right;
  line-height: 61px;
  max-height: 61px;
  ${below.md} {
    float: left;
  }
`
const HourBlock = styled.div`
  height: 59px;
  border-bottom: 1px solid #dddddd;
  filter: brightness(93%);

  &:hover {
    filter: brightness(100%);
    transition: 0.3s;
  }
`
const EmptyHourBlock = styled(HourBlock)`
  height: 60px;
  border-bottom: 1px solid #dddddd;
`
const Mod1Block = styled.div`
  height: 56px;
  cursor: pointer;
  width: 99%;
  background-color: #ff971a;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-top: 1px;
`
const Mod2Block = styled(Mod1Block)`
  background-color: #c01a1a;
`
const Kids1Block = styled(Mod1Block)`
  background-color: #2bb958;
`
const Kids2Block = styled(Mod1Block)`
  background-color: #222222;
`
const TeensBlock = styled(Mod1Block)`
  background-color: #168fff;
`
const Mod15Block = styled(TeensBlock)`
  height: 70px;
`
const AdvancedBlock = styled(Mod1Block)`
  background-color: #222222;
  height: 88px;
`
const LongModule2NoGi = styled(AdvancedBlock)`
  background-color: #c01a1a;
`
const AdvancedBlockShort = styled(AdvancedBlock)`
  height: 56px;
`
const OpenMatBlock = styled(Mod1Block)`
  background-color: #222222;
`
const PrivateBlock = styled.div`
  background-color: #f4f5f5;
  height: 117px;
  border-radius: 3px;
  margin-top: 1px;
  cursor: pointer;
  width: 99%;
`
const PrivateBlockShort = styled(PrivateBlock)`
  height: 56px;
`
const WhiteText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.color.white};
  margin-left: 15px;
`
const PrivateText = styled(WhiteText)`
  color: #bbbbbb;
  margin-left: 16px;
  padding-top: 18px;
`
const OpenMatText = styled(PrivateText)`
  color: ${props => props.theme.color.white};
  padding-top: 0px;
`
const MatTitle = styled.div
`
  margin-top: 15px;
  font-size: 24px;
  font-weight: 600;
`
const ModalContainer = styled.div`
  width: 572px;
  height: 422px;
  background-color: #ffffff;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.3);
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: inline-block;
  animation: ${props => (props.showModal ? fadeIn : fadeOut)} 0.4s ease-out;

  ${below.md} {
    width: 80%;
    height: auto;
    padding-bottom: 45px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const InnerModal = styled.div`
  margin: auto;
  width: 85%;
  margin-top: 50px;
  opacity: ${props => (props.showModal ? 1 : 0)};
  transition: opacity 1s ease-in;
`
const ModalTopRow = styled.div`
  display: flex;
`
const ModalColorBlock = styled.div`
  height: 60px;
  width: 58px;
  border-radius: 3px;

  ${below.md} {
    height: 54px;
    width: 52px;
  }
`
const ModalColorBlockMod1 = styled(ModalColorBlock)`
  background-color: #ff7600;
`
const ModalColorBlockMod2 = styled(ModalColorBlock)`
  background-color: #e22b2b;
`
const ModalColorBlockKids1 = styled(ModalColorBlock)`
  background-color: #2bb958;
`
const ModalColorBlockKids2 = styled(ModalColorBlock)`
  background-color: #222222;
`
const ModalColorBlockTeens = styled(ModalColorBlock)`
  background-color: #168fff;
`
const ModalColorOpenMatOrAdvanced = styled(ModalColorBlock)`
  background-color: #222222;
`

const ModalTitleText = styled.div`
  color: #222222;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -0.51px;
  line-height: 47px;
  margin-left: 20px;
  flex: 2;
  ${below.md} {
    margin-left: 6px;
    font-size: 32px;
  }
`
const ModalMainText = styled.div`
  margin-top: 31px;
  margin-bottom: 31px;
  color: #888888;
  font-size: 18px;
  letter-spacing: -0.24px;
  ${below.md} {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 30px;
  }
`
const ModalBoldText = styled.div`
  font-size: 18px;
  font-weight: bold;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`
const CloseModal = styled(FaTimes)`
  font-size: 32px;
  font-weight: 300;
  font-size: 40px;
  color: #222222;
  margin-top: -10px;

  ${below.md} {
    margin-left: 25px;
    font-size: 32px;
  }
`
const RedStar = styled(FaStar)`
  font-size: 12px;
  color: #d22828;
  margin-right: 2px;
`
const RedText = styled.div`
  color: #d22828;
  font-size: 14px;
`
const Modal = props => {
  let modalColorBlock

  //Dynamic color blocks
  if (props.className == 'Module 1') {
    modalColorBlock = <ModalColorBlockMod1 />
  } else if (props.className == 'Module 2') {
    modalColorBlock = <ModalColorBlockMod2 />
  } else if (props.className == ('Kids (Age 2-4)' || 'Kids (Age 3-4)' || 'Kids (Age 1-2)')) {
    modalColorBlock = <ModalColorBlockKids2 />
  } else if (props.className == 'Kids (Age 5-7)') {
    modalColorBlock = <ModalColorBlockKids1 />
  } else if (props.className == 'Kids (Age 8-11)') {
    modalColorBlock = <ModalColorBlockKids2 />
  } else if (
    props.className == 'Teens' ||
    'HIIT' ||
    'Kids (Age 11-13)' ||
    'Kids No-Gi (Age 8-13)' ||
    'Teens (Age 12-14)' || 
    'Teens No-Gi (Age 12-14)' || 
    'Module 1.5'
  ) {
    modalColorBlock = <ModalColorBlockTeens />
  } else if (props.className == 'Wrestling') {
    modalColorBlock = <ModalColorBlockMod2 />
  } else if (
    props.className == 'Open Mat' ||
    'Open Mat (12:15pm)' ||
    'Open Mat (10:45 am)' ||
    'Advanced (No-Gi)' ||
    'Advanced'
  ) {
    modalColorBlock = <ModalColorOpenMatOrAdvanced />
  }

  if (props.showModal) {
    return (
      <ModalContainer showModal={props.showModal}>
        <InnerModal showModal={props.showModal}>
          <ModalTopRow>
            {modalColorBlock}
            <ModalTitleText>{props.modalHeader}</ModalTitleText>
            <CloseModal onClick={props.toggleModal} />
          </ModalTopRow>
          <ModalMainText>{props.modalBody}</ModalMainText>
          <ModalBoldText>{props.modalRequirements}</ModalBoldText>
        </InnerModal>
      </ModalContainer>
    )
  } else {
    return <div />
  }
}
class Schedule extends React.Component {
  constructor(props) {
    super(props)
    let displayType = 'weekly'
    if (typeof window !== `undefined` && window.innerWidth < breakpointIntegers.md) {
      displayType = 'daily'
    }

    this.toggleModal = this.toggleModal.bind(this)

    this.state = {
      showModal: false,
      modalName: '',
      modalHeader: '',
      modalBody: '',
      modalRequirements: '',
      displayType: displayType
    }
  }

  toggleModal(className, modalHeader, modalBody, modalRequirements) {
    this.setState({
      showModal: !this.state.showModal,
      modalName: className,
      modalHeader: modalHeader,
      modalBody: modalBody,
      modalRequirements: modalRequirements
    })
  }

  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < breakpointIntegers.md && this.state.displayType !== 'daily') {
        this.setState({
          displayType: 'daily'
        })
      } else if (window.innerWidth > breakpointIntegers.md && this.state.displayType !== 'weekly') {
        this.setState({
          displayType: 'weekly'
        })
      }
    }
  }
  render() {
    let scheduleTimes = [
      '6:00 am',
      '7:00 am',
      '10:00 am',
      '11:00 am',
      '12:00 pm',
      '1:00 pm',
      '4:15 pm',
      '5:00 pm',
      '6:00 pm',
      '7:00 pm',
      '8:00 pm',
      '9:00 pm',
      '10:00 pm'
    ]
    let timeBlocks = scheduleTimes.map((time, i) => {
      return <GreyText key={i}>{time}</GreyText>
    })
    let dailyTimeColumn = <TimeColumn>{timeBlocks}</TimeColumn>
    let timeDisplay = <TimeContainer>{dailyTimeColumn}</TimeContainer>
    if (this.state.displayType == 'daily') {
      //Display time everyday for daily view on mobile
      timeDisplay = (
        <TimeContainer>
          {dailyTimeColumn}
          {dailyTimeColumn}
          {dailyTimeColumn}
          {dailyTimeColumn}
          {dailyTimeColumn}
          {dailyTimeColumn}
          {dailyTimeColumn}
        </TimeContainer>
      )
    }

    let scheduleData = this.props.block.scheduleDays.map((daySchedule, i) => {
      let timeSlots = [
        '6Am',
        '7Am',
        '10Am',
        '11Am',
        '12Pm',
        '1Pm',
        '415Pm',
        '5Pm',
        '6Pm',
        '7Pm',
        '8Pm',
        '9Pm'
      ]

      let hourBlocks = {}

      //Fill in the hourBlocks object
      timeSlots.forEach(timeSlot => {
        let blockName = 'block' + timeSlot
        hourBlocks[blockName] = ''
      })
      //Generate dynamic components for scheduled classes
      timeSlots.forEach(timeSlot => {
        let classInfo = daySchedule['_rawTimeSlot' + timeSlot]

        //Check if the block is null or has a class
        if (classInfo) {
          let componentNames = {
            'Module 1': {
              componentName: Mod1Block
            },
            'Module 2': {
              componentName: Mod2Block
            },
            'Module 1.5': {
              componentName: Mod15Block
            },
            Advanced: {
              componentName: AdvancedBlock
            },
            'Kids (Age 2-4)': {
              componentName: Kids2Block
            },
            'Kids (Age 1-2)': {
              componentName: Kids2Block
            },
            'Kids (Age 3-4)': {
              componentName: Kids2Block
            },
            'Kids (Age 5-7)': {
              componentName: Kids1Block
            },
            'Kids (Age 8-11)': {
              componentName: Kids2Block
            },
            'Teens (Age 12-14)': {
              componentName: TeensBlock
            },
            'Teens No-Gi (Age 12-14)': {
              componentName: TeensBlock
            },
            'Kids No-Gi (Age 8-13)': {
              componentName: TeensBlock
            },
            Teens: {
              componentName: TeensBlock
            },
            HIIT: {
              componentName: TeensBlock
            },
            'Module 2 (No-Gi)': {
              componentName: Mod2Block
            },
            'Module 2 ': {
              componentName: LongModule2NoGi
            },
            'Module 2 (No-Gi) ': {
              componentName: LongModule2NoGi
            },
            'Advanced (No-Gi)': {
              componentName: AdvancedBlock
            },
            'Open Mat': {
              componentName: OpenMatBlock
            },
            'Open Mat (12:15pm)': {
              componentName: OpenMatBlock
            },
            'Open Mat (10:45 am)': {
              componentName: OpenMatBlock
            },
            'Advanced (Sparring)': {
              componentName: AdvancedBlockShort
            },
            'Private Classes (2 hours)': {
              componentName: PrivateBlock
            },
            'Private Classes (1 hour)': {
              componentName: PrivateBlockShort
            },
            Wrestling: {
              componentName: Mod2Block
            },
            'Jurassic Jiu-Jitsu (30+)': {
              componentName: Mod1Block
            }
          }

          let ComponentName = componentNames[classInfo.name].componentName
          let TextComponentName = WhiteText
          let displayName = classInfo.name
          if (
            classInfo.name == 'Private Classes (1 hour)' ||
            classInfo.name == 'Private Classes (2 hours)'
          ) {
            TextComponentName = PrivateText
            displayName = 'Private Classes'
          } else if (classInfo.name == 'Open Mat' || 'Open Mat (12:15pm)') {
            TextComponentName = OpenMatText
          }

          hourBlocks['block' + timeSlot] = (
            <HourBlock
              onClick={() => {
                this.toggleModal(
                  classInfo.name,
                  classInfo.modalHeader,
                  classInfo.modalBody,
                  classInfo.modalRequirement
                )
              }}
            >
              <ComponentName>
                <TextComponentName>
                  {displayName}
                  {classInfo.newClass ? (
                    <RedText>
                      <RedStar />
                      New Class
                    </RedText>
                  ) : null}
                </TextComponentName>
              </ComponentName>
            </HourBlock>
          )
        } else {
          hourBlocks['block' + timeSlot] = <EmptyHourBlock />
        }
      })
      //Object.keys returns an array of keys from the hourBlocks object
      let displayHourBlocks = Object.keys(hourBlocks).map((hourBlock, i) => {
        return <span key={i}>{hourBlocks[hourBlock]}</span>
      })
      return (
        <DayColumn key={i}>
          <WeekdayText>{daySchedule.scheduleDay}</WeekdayText>
          {displayHourBlocks}
        </DayColumn>
      )
    })
    return (
      <Root>
        <ButtonContainer><a href = {schedulePdf} target = "_blank"><Button>View full schedule PDF <FaRegFilePdf /></Button></a>
        &nbsp;&nbsp;<a href = '/schedule#mat2Schedule'><Button>View Mat 2 Schedule <FaCalendarAlt /></Button></a></ButtonContainer>
        <ButtonContainer><MatTitle>Mat 1 Schedule</MatTitle></ButtonContainer>
        <Container>
          <Modal
            showModal={this.state.showModal}
            className={this.state.modalName}
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            modalRequirements={this.state.modalRequirements}
            toggleModal={this.toggleModal}
          />
          {timeDisplay}
          <DaysContainer>{scheduleData}</DaysContainer>
        </Container>
      </Root>
    )
  }
}

export default Schedule
